<template>
    <b-container fluid>
        <!-- <b-col cols="12">
          <div class="iq-card mb-0" style="border-bottom-left-radius: 0; border-bottom-right-radius: 0" id="menu">
            <div id="menu-navi" class="iq-card-body">
              <button type="button" class="btn mr-1 btn-outline-primary" data-action="move-today">Today</button>
              <b-row>
                <b-col cols="6">
                  <div class="feild">
                    <b-form-input
                      type="month"
                      v-model="selectedDate"
                      required
                    ></b-form-input>
                  </div>
                </b-col>
              </b-row>
              </div>
            </div>
        </b-col> -->
        <div class="edit-price">
          <b-modal id="room-info" title="Room Info" size="" hide-footer>
            <div class="room-info">
              <b-row>
                <b-col sm="6" class="mb-3">
                  <div class="info px-2 py-1 rounded">
                      <span>
                        Room Name :
                      </span>
                      <span>
                        {{ showRoom.name }}
                      </span>
                  </div>
                </b-col>
                <b-col sm="6" class="mb-3">
                  <div class="info px-2 py-1 rounded">
                      <span>
                        Child Count :
                      </span>
                      <span>
                        {{ showRoom.child_number }}
                      </span>
                  </div>
                </b-col>
                <b-col sm="6" class="mb-3">
                  <div class="info px-2 py-1 rounded">
                      <span>
                        Adult Count :
                      </span>
                      <span>
                        {{ showRoom.adult_number }}
                      </span>
                  </div>
                </b-col>
                <b-col sm="6" class="mb-3">
                  <div class="info px-2 py-1 rounded">
                      <span>
                        Bathroom Number :
                      </span>
                      <span>
                        {{ showRoom.bathroom_number }}
                      </span>
                  </div>
                </b-col>
                <b-col sm="6" class="mb-3">
                  <div class="info px-2 py-1 rounded">
                      <span>
                        Floor :
                      </span>
                      <span v-if="showRoom.floor">
                        {{ showRoom.floor.number }}
                      </span>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-modal>
        </div>

          <FullCalendar @datesSet="nextBtnEvent" :options='calendarOptions'>
            <template v-slot:eventContent='arg'>
                <div class="text-center">
                  <i style="font-size: 14px;">{{ $t('room-name') }} : {{ arg.event.title }}</i>
                </div>
            </template>
          </FullCalendar>
    </b-container>
  </template>
<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
export default {
  name: 'App',
  components: {
    FullCalendar
  },
  data () {
    return {
      showRoom: {},
      selectedDate: '',
      payload: {
        price: '',
        start_date: '',
        end_date: ''
      },
      editPayload: {
        price: ''
      },
      calendarOptions: {
        plugins: [dayGridPlugin],
        height: 700,
        initialView: 'dayGridMonth',
        weekends: true,
        eventClick: this.handleDateClick,
        datesSet: this.nextBtnEvent,
        events: [],
        showNonCurrentDates: false
      }
    }
  },
  watch: {

  },
  methods: {
    // handleDateClick: function (clickInfo) {
    //   this.$bvModal.show('room-info')
    //   this.showRoom = clickInfo.event._def.extendedProps.room
    // },
    getReservationsData (date) {
      this.getReservations(date).then(() => {
        var eventData = []
        this.reservstions.forEach(el => {
          el.rooms.map(room => {
            eventData.push({
              title: room.name,
              start: el.date,
              room: room
            })
          })
        })
        this.calendarOptions.events = eventData
      })
    },
    nextBtnEvent (info) {
      this.getReservationsData(info.startStr.slice(0, 7))
      // this.getPriceData(
      //   {
      //     from: info.startStr.slice(0, 10),
      //     to: info.endStr.slice(0, 10)
      //   }
      // )
    }

  },

  mounted () {
  }
}
</script>
<style>
.fc-scrollgrid-sync-table {
  width: 100% !important;
}
.room-info .info{
  border: 2px dashed #ccc;
}

</style>
